import React, { useEffect } from 'react';

function Menu({ menuActive, toggleMenu }) {
  useEffect(() => {
    const handleMenuClick = (event) => {
      if (event.target.tagName === 'A') {
        event.preventDefault();
        const target = event.target.getAttribute('href');
        document.querySelector(target).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        toggleMenu();
      }
    };

    const mobileMenu = document.getElementById('mobile-menu');
    mobileMenu?.addEventListener('click', handleMenuClick);

    return () => {
      mobileMenu?.removeEventListener('click', handleMenuClick);
    };
  }, [toggleMenu]);

  return (
    <nav id="mobile-menu" className={`mobile-menu ${menuActive ? 'active' : ''}`}>
      <span className="close-menu" id="close-menu" onClick={toggleMenu}>
        &times;
      </span>
      <ul>
        <li><a href="#hookahs">Кальяны</a></li>
        <li><a href="#author-tea">Чай Авторский</a></li>
        <li><a href="#chines-tea">Китайский чай</a></li>
        {/* <li><a href="#signature-tea">Чай Фирменный</a></li> */}
        <li><a href="#classic-tea">Чай классический</a></li>
        <li><a href="#coffee">Кофе</a></li>
        <li><a href="#drinks">Напитки</a></li>
        <li><a href="#redbull">Red Bull</a></li>
        <li><a href="#lemonades">Лимонады</a></li>
        <li><a href="#beer">Пиво</a></li>
        <li><a href="#snacks">Закуски</a></li>
        <li><a href="#kitchen">Кухня</a></li>
      </ul>
    </nav>
  );
}

export default Menu;
