import React from 'react';
import { motion, useScroll } from 'framer-motion';
import Header from './components/Header';
import MenuBlock from './components/MenuBlock';
import Footer from './components/Footer';
import './assets/styles.css';

function App() {
  const { scrollYProgress } = useScroll();
  // Блок Кальяны
  const hookahItems = [
    { name: 'Стандарт', price: '1100р', isNew: true },
    { name: 'Танж/Бонч', price: '1400р', isNew: true },
    { name: 'Кальян х2', price: '1900р', isNew: true },
    { name: 'Замена чаши', price: '900р', isNew: true },
    { name: 'Бизнес кальян', price: '850р', detail: 'С 12:00 до 18:00 по будням', isNew: true },
    { name: 'Кальян полуночник', price: '850р', detail: 'Ежедневно с 23:00 и до закрытия', isNew: true }
  ];
  //Блок Классический чай
  const classicTeaItems = [
    { name: 'Ассам', price: '270/370р', isNew: true },
    { name: 'Эрл Грей', price: '270/370р', isNew: true },
    { name: 'Чабрец', price: '270/370р', isNew: true },
    { name: 'Альпийский', price: '270/370р', isNew: true },
    { name: 'Сенча', price: '270/370р', isNew: true },
    { name: 'Молочный улун', price: '270/370р', isNew: true },
    // { name: 'Те Гуань Инь', price: '320/420р' },
    { name: 'Жасминовый', price: '270/370р', isNew: true },
    { name: 'Персиковый улун', price: '270/370р', isNew: true },
    { name: 'Веселый фрукт', price: '270/370р', isNew: true },
    // { name: 'Королевский пуэр', price: '320/420р' }
  ];
  //Блок Фирменный чай
  const signatureTeaItems = [
    { name: 'Брусника Ежевика Имбирь', price: '450р', isNew: true },
    { name: 'Глинтвейн с малиной', price: '450р', isNew: true },
    { name: 'Ежевика мята', price: '450р', isNew: true },
    { name: 'Тайский с грейпфрутом', price: '450р', isNew: true },
    { name: 'Лесные ягоды', price: '450р', isNew: true },
    { name: 'Голубая массала (На молоке)', price: '480р', isNew: true },
    { name: 'Альпийский со смородиной', price: '450р', isNew: true }
  ];
  // Блок Авторский чай
  const authorTeaItems = [
    { name: 'Новогодний', price: '550р', detail: '(Мандарин, пихта, мята, апельсин, мёд)', isNew: true },
    { name: 'Тропический', price: '550р', detail: '(Манго, маракуйя, груша, яблоко, мёд)', isNew: true },
    { name: 'Земляника-ананас', price: '550р', detail: '(Ананас, вишня, земляника, гвоздика, мёд)', isNew: true },
    { name: 'Облепиха апельсин яблоко', price: '550р', detail: '(Апельсин, облепиха, яблоко, мёд)', isNew: true },
    { name: 'Клюквенный глинтвейн', price: '550р', detail: '(Клюква, апельсин, гвоздика, анис, мёд)', isNew: true },
    { name: 'Адские ягоды', price: '550р', detail: '(Лесные ягоды, бадьян, корица, перец)', isNew: true },
    { name: 'Клубника малина лайм', price: '550р', detail: '(Клубника, малина, лайм, мёд)', isNew: true },
    { name: 'Груша смородина корица', price: '550р', detail: '(Груша, смородина, корица, мята, мёд)', isNew: true },
    { name: 'Гранатовый', price: '550р', detail: '(Гранатовый сок, яблоко, лимон, мёд)', isNew: true },
    { name: 'Вишня-цитрус', price: '550р', detail: '(Апельсин, вишня, лимон, корица, мёд)', isNew: true },
    // { isAdditional: true, name: 'Любой чай можем сделать без мёда'}
  ];
    // Блок Китайский чай
    const chinesTeaItems = [
    { name: 'Габа Алишань 佳叶龙茶', price: '800р', detail:
          '(Тайваньский улун с гор Алишань, обладает сильным успокаивающем и концентрирующим эффектом, нежным вкусом и богатым ароматом)', isNew: true },
    { name: 'Шу Пуэр', price: '700р', detail: '(Классический Шу, обладает мощным тонизирующим эффектом)', isNew: true },
    { name: 'Шен Пуэр 2018г 生普洱茶', price: '700р', detail: '(Выдержанный Шэн из деревни Нака, расслабляет, успокаивает, снимает стресс)', isNew: true },
    // { name: 'Шен Пуэр 2018г 生普 )' },
    { name: 'Мао Фэн 毛峰', price: '550р', detail:
          '(Красный чай родом из провинции Юньнань, вкус фруктовый с медовыми оттенками)', isNew: true },
    { name: 'Да Хун Пао 大红袍', price: '650р', detail: '(Утёсный улун сильной ферментации, вкус карамельный с фруктовыми нотками)', isNew: true },
    { name: 'Тегуаньи́нь 鐵觀音', price: '550р', detail: '(Классический улун с насыщенным цветочным ароматом и мягким вкусом)', isNew: true },
    // { name: '', price: '550р', detail: '()' },
  ];
  // Блок кофе
  const coffeeItems = [
    { name: 'Эспрессо/Двойной', price: '120/150р', isNew: true },
    { name: 'Американо/Двойной', price: '150/200р', isNew: true },
    { name: 'Капучино (200/400мл)', price: '200/250р', isNew: true },
    { name: 'Капучино на альтер. молоке', price: '250/320р', isNew: true },
    { name: 'Латте', price: '220р', isNew: true },
    { name: 'Айс-Кофе', price: '300р', isNew: true },
    { name: 'Флэт Уайт', price: '250р', isNew: true },
    { name: 'Гляссе', price: '280р', isNew: true },
    { name: 'Какао', price: '250р', isNew: true }
  ];
  // Блок напитки
  const drinksItems = [
    { name: 'BonAqua газ/не газ (0.5л)', price: '150р', isNew: true },
    { name: 'Borjomi (0.5л)', price: '220р', isNew: true },
    { name: 'Газировка импорт', price: '250р', isNew: true },
    { name: 'Свежевыжатый сок', price: '300р', isNew: true },
    { name: 'Милкшейк классик', price: '300р', isNew: true },
    { name: 'Добрый в ассортименте', price: '150р', isNew: true },
    { name: 'Сок Rich (стекло)', price: '180р', isNew: true }
  ]
  // Блок red bull
    const redbullItems = [
    { name: 'Red Bull Energy Drink', price: '250р', detail: '(Классический)', isNew: true },
    { name: 'Red Bull Sugarfree', price: '250р', detail: '(Без сахара)', isNew: true },
    { name: 'Red Bull Summer Edition', price: '250р', detail: '(Куруба и цветы бузины)', isNew: true },
    { name: 'Red Bull Red Edition', price: '250р', detail: '(Арбузный)', isNew: true },
    { name: 'Red Bull Tropical Edition', price: '250р', detail: '(Тропический)', isNew: true },
    { name: 'Red Bull Blue Edition', price: '250р', detail: '(Черничный)', isNew: true },
    { name: 'Red Bull White Edition', price: '250р', detail: '(Кокосовый)', isNew: true }
  ];
  // Блок лимонады
  const lemonadeItems = [
    { name: 'Цитрусовый', price: '320/650р', detail: '(Лайм, лимон, апельсин, мята, лед)', isNew: true },
    { name: 'Мохито', price: '320/650р', detail: '(Лайм, мята, сахарный сироп, лед)', isNew: true },
    { name: 'Киви Фейхоа', price: '320/650р', detail: '(Киви, фейхоа, лимон, лед)', isNew: true },
    { name: 'Вишня юзу', price: '320/650р', detail: '(Вишня, лимон, мята, лед)', isNew: true },
    { name: 'Лесные ягоды', price: '320/650р', detail: '(Микс лесных ягод, лимон, лед)', isNew: true },
    { name: 'Ананас Земляника', price: '320/650р', detail: '(Основа ананас, земляника, лимон, лед)', isNew: true },
    { name: 'Апельсин Манго Маракуйя', price: '320/650р', detail: '(Манго, маракуйя, апельсин, лед)', isNew: true },
    { name: 'Лавандовый', price: '320/650р', detail: '(Лавандовый тоник, лайм, мята, лед)', isNew: true }
  ];
    // Блок Пиво
    const beerItems = [
    { name: 'Джоус APA 0,5л Алк. 5.5%', price: '380р', detail: '(С цитрусовыми и тропическими оттенками)', isNew: true },
    { name: 'Джоус Stout 0,5л Алк. 5.2%', price: '380р', detail: '(Темный эль с шоколадом и кофе)', isNew: true },
    { name: 'Джоус Weizen 0.5л Алк. 5.6%', price: '380р', detail: '(Немецкая пшеничная классика)', isNew: true },
    { name: 'Джоус Blanche 0.5л Алк. 4.6%', price: '380р', detail: '(Бланш с кориандром и памеранца)', isNew: true },
    { name: 'Джоус Lager 0.5л Алк. 5.3%', price: '380р', detail: '(Светлый лагер с солодовым вкусом)', isNew: true },
    { name: 'Джоус Helles 0.5л Алк. 4.5%', price: '380р', detail: '(Классический светлый лагер)', isNew: true },
    { name: 'Джоус Citraizen 0.5л Алк. 5.0%', price: '380р', detail: '(Пшеничный эль с цитрусовыми нотами)', isNew: true },
    { name: 'Джоус Pale Ale 0.5л Алк. 5.2%', price: '380р', detail: '(Британский эль с бисквитным профилей)', isNew: true },
    { name: 'Джоус Екатерина 0.33л Алк. 8.5%', price: '380р', detail: '(Имперский темный стаут с кислинкой)', isNew: true }
  ];
  // Блок закуски
  const snacksItems = [
    { name: 'Фисташки (100г)', price: '450р', isNew: true },
    { name: 'Арахис (120г)', price: '200р', isNew: true },
    { name: 'Кальмар (70г)', price: '250р', isNew: true },
    { name: 'Чипсы', price: '350р', isNew: true },
    { name: 'Чоко-пай', price: '50р', isNew: true },
    { name: 'Печенье Oreo', price: '200р', isNew: true },
    { name: 'Шоколад Ritter Sport (100г/16г)', price: '280/50р', isNew: true }
  ];

  // Блок кухня
  const kitchenItems = [
    { name: 'Римская Пицца Маргарита', price: '650р', isNew: true },
    { name: 'Римская Пицца 4 сыра', price: '700р', isNew: true },
    { name: 'Римская Пицца Баварская мясная', price: '720р', isNew: true },
    // { name: 'Римская Пицца Груша горгондзола', price: '700р' },
    { name: 'Римская Пицца Пепперони', price: '720р', isNew: true },
    { name: 'Картофель фри (200г)', price: '250р', isNew: true },
    { name: 'Луковые кольца (150г)', price: '250р', isNew: true },
    { name: 'Сырные шарики (150г)', price: '300р', isNew: true },
    { name: 'Сырные палочки (150г)', price: '300р', isNew: true },
    { name: 'Картофельные палочки (150г)', price: '250р', isNew: true },
    { name: 'Стрипсы (180г)', price: '300р', isNew: true },
    { name: 'Куриный попкорн (150г)', price: '280р', isNew: true },
    { name: 'Наггетсы (150г)', price: '280р', isNew: true }
  ];

  return (
    <div className="container">
      <Header />

      {/* Прогресс бар прокрутки */}
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '5px',
          backgroundColor: 'red',
          transformOrigin: '0%',
          scaleX: scrollYProgress,
          zIndex: 1000,
          width: '100%',
        }}
      />

      <main>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="hookahs" title="Кальяны" items={hookahItems}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="author-tea" title="Чай Авторский 1л" items={authorTeaItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="signature-tea" title="Чай Фирменный 1 л" items={signatureTeaItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="chines-tea" title="Китайский чай" items={chinesTeaItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="classic-tea" title="Чай классический 0,6 / 1 л" items={classicTeaItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="coffee" title="Кофе" items={coffeeItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="drinks" title="Напитки" items={drinksItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="redbull" title="Red Bull 0.25" items={redbullItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="lemonades" title="Лимонады 0,4 / 1л" items={lemonadeItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="beer" title="Пиво" items={beerItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="snacks" title="Закуски" items={snacksItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="kitchen" title="Кухня" items={kitchenItems} isNewLabel={true}/>
        </motion.div>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
