import React from 'react';
import { animate } from 'framer-motion';
import Menu from './Menu';

function Header() {
  const [menuActive, setMenuActive] = React.useState(false);

  const toggleMenu = () => {
    const menu = document.querySelector('.mobile-menu');

    if (!menuActive) {
      setMenuActive(true);
      animate(menu, { x: 0 }, { duration: 0.3 });
    } else {
      animate(menu, { x: '-100%' }, { duration: 0.1 }).then(() => {
        setMenuActive(false);
      });
    }
  };

  return (
    <header>
      <div className="header-content">
        <button className="burger-menu" id="burger-menu" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </button>
        <h1 className="random-gradient">MIST LOUNGE</h1>
        <div className="social-icons">
          <a href="https://www.instagram.com/lounge_bar_mist?igsh=eGR2ejVxb3UzcTkz" target="_blank" rel="noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://vk.com/mist_lounge_ekb" target="_blank" rel="noreferrer">
            <i className="fab fa-vk"></i>
          </a>
        </div>
      </div>

      <Menu menuActive={menuActive} toggleMenu={toggleMenu} />
    </header>
  );
}

export default Header;
