import React from 'react';

function Footer() {
  return (
    <footer>
      <p>г.Екатеринбург, ул. Восточная 68</p>
      <p>Все права защищены (с) 2025</p>
        <p><a href="tel:+79826776385" className="phone-link">Тел. +7-982-677-63-85</a></p>
    </footer>
  );
}

export default Footer;
