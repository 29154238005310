import React from 'react';
import { motion, useScroll } from 'framer-motion';
import Header from './components/Header';
import MenuBlock from './components/MenuBlock';
import Footer from './components/Footer';
import './assets/styles.css';

function App() {
  const { scrollYProgress } = useScroll();
  // Блок Кальяны
  const hookahItems = [
    { name: 'Стандарт', price: '1200р', isNew: true },
    { name: 'Танж/Бонч', price: '1500р', isNew: true },
    { name: 'Кальян х2', price: '2000р', isNew: true },
    { name: 'Замена чаши', price: '950р', isNew: true },
    { name: 'Бизнес кальян', price: '900р', detail: 'С 12:00 до 18:00 по будням', isNew: true },
    { name: 'Кальян полуночник', price: '900р', detail: 'Ежедневно с 23:00 и до закрытия', isNew: true }
  ];
  //Блок Классический чай
  const classicTeaItems = [
    { name: 'Ассам', price: '270/370р', isNew: true },
    { name: 'Эрл Грей', price: '270/370р', isNew: true },
    { name: 'Чабрец', price: '270/370р', isNew: true },
    { name: 'Альпийский', price: '270/370р', isNew: true },
    { name: 'Сенча', price: '270/370р', isNew: true },
    { name: 'Молочный улун', price: '270/370р', isNew: true },
    { name: 'Жасминовый', price: '270/370р', isNew: true },
    { name: 'Персиковый улун', price: '270/370р', isNew: true },
    { name: 'Веселый фрукт', price: '270/370р', isNew: true },
  ];

  // Блок Авторский чай
  const authorTeaItems = [
    { name: 'Новогодний', price: '550р', detail: '(Мандарин, пихта, мята, апельсин, мёд)', isNew: true },
    { name: 'Тропический', price: '550р', detail: '(Манго, маракуйя, груша, яблоко, мёд)', isNew: true },
    { name: 'Земляника-ананас', price: '550р', detail: '(Ананас, вишня, земляника, гвоздика, мёд)', isNew: true },
    { name: 'Облепиха апельсин яблоко', price: '550р', detail: '(Апельсин, облепиха, яблоко, мёд)', isNew: true },
    { name: 'Клюквенный глинтвейн', price: '550р', detail: '(Клюква, апельсин, гвоздика, анис, мёд)', isNew: true },
    { name: 'Адские ягоды', price: '550р', detail: '(Лесные ягоды, бадьян, корица, перец)', isNew: true },
    { name: 'Клубника малина лайм', price: '550р', detail: '(Клубника, малина, лайм, мёд)', isNew: true },
    { name: 'Груша смородина корица', price: '550р', detail: '(Груша, смородина, корица, мята, мёд)', isNew: true },
    { name: 'Вишня-цитрус', price: '550р', detail: '(Апельсин, вишня, лимон, корица, мёд)', isNew: true },
    // { isAdditional: true, name: 'Любой чай можем сделать без мёда'}
  ];

      // Блок Китайский чай
  const chinesTeaItems = [
    { name: 'Габа Алишань 佳叶龙茶', price: '800р', detail:
          '(Тайваньский улун с гор Алишань, обладает сильным успокаивающем и концентрирующим эффектом, нежным вкусом и богатым ароматом)', isNew: true },
    { name: 'Шу Пуэр', price: '700р', detail: '(Классический Шу, обладает мощным тонизирующим эффектом)', isNew: true },
    { name: 'Шен Пуэр 2018г 生普洱茶', price: '700р', detail: '(Выдержанный Шэн из деревни Нака, расслабляет, успокаивает, снимает стресс)', isNew: true },
    // { name: 'Шен Пуэр 2018г 生普 )' },
    { name: 'Мао Фэн 毛峰', price: '550р', detail:
          '(Красный чай родом из провинции Юньнань, вкус фруктовый с медовыми оттенками)', isNew: true },
    { name: 'Да Хун Пао 大红袍', price: '650р', detail: '(Утёсный улун сильной ферментации, вкус карамельный с фруктовыми нотками)', isNew: true },
    { name: 'Тегуаньи́нь 铁观音', price: '550р', detail: '(Классический улун с насыщенным цветочным ароматом и мягким вкусом)', isNew: true },
    // { name: '', price: '550р', detail: '()' },
  ];

    // Блок лимонады
    const lemonadeItems = [
      { name: 'Цитрусовый', price: '320/650р', detail: '(Лайм, лимон, апельсин, мята, лед)', isNew: true },
      { name: 'Мохито', price: '320/650р', detail: '(Лайм, мята, сахарный сироп, лед)', isNew: true },
      { name: 'Киви Фейхоа', price: '320/650р', detail: '(Киви, фейхоа, лимон, лед)', isNew: true },
      { name: 'Вишня юзу', price: '320/650р', detail: '(Вишня, лимон, мята, лед)', isNew: true },
      { name: 'Лесные ягоды', price: '320/650р', detail: '(Микс лесных ягод, лимон, лед)', isNew: true },
      { name: 'Ананас Земляника', price: '320/650р', detail: '(Основа ананас, земляника, лимон, лед)', isNew: true },
      { name: 'Апельсин Манго Маракуйя', price: '320/650р', detail: '(Манго, маракуйя, апельсин, лед)', isNew: true },
      { name: 'Лавандовый', price: '320/650р', detail: '(Лавандовый тоник, лайм, мята, лед)', isNew: true }
    ];

  // Блок кофе
  const coffeeItems = [
    { name: 'Эспрессо/Двойной', detail: '30/60мл', price: '120/150р', isNew: true },
    { name: 'Американо/Двойной', detail: '200/400мл', price: '150/200р', isNew: true },
    { name: 'Капучино', detail: '200/400мл', price: '200/250р', isNew: true },
    { name: 'Капучино на альтер. молоке', price: '250/320р', isNew: true },
    { name: 'Латте', detail: '250мл', price: '220р', isNew: true },
    { name: 'Айс-Кофе', detail: '300мл', price: '300р', isNew: true },
    { name: 'Флэт Уайт', detail: '200мл', price: '250р', isNew: true },
    { name: 'Гляссе', detail: '300мл', price: '280р', isNew: true },
    { name: 'Какао', detail: '300мл', price: '250р', isNew: true }
  ];
  // Блок напитки
  const drinksItems = [
    { name: 'BonAqua газ/не газ', detail: '0.5л', price: '150р', isNew: true },
    { name: 'Borjomi', detail: '0.5л', price: '220р', isNew: true },
    { name: 'Газировка импорт', detail: '0.33л', price: '250р', isNew: true },
    { name: 'Свежевыжатый сок', detail: '0.25л', price: '300р', isNew: true },
    { name: 'Милкшейк классик', detail: '0.3л', price: '300р', isNew: true },
    { name: 'Добрый в ассортименте', detail: '0.5л', price: '150р', isNew: true },
    { name: 'Сок Rich (стекло)', detail: '0.25л', price: '180р', isNew: true }
  ];
  // Блок закуски
  const snacksItems = [
    { name: 'Фисташки', detail: '100г', price: '450р', isNew: true },
    { name: 'Арахис', detail: '120г', price: '200р', isNew: true },
    { name: 'Кальмар', detail: '70г', price: '250р', isNew: true },
    { name: 'Чипсы', detail: '150г', price: '350р', isNew: true },
    { name: 'Чоко-пай', detail: '30г', price: '50р', isNew: true },
    { name: 'Печенье Oreo', detail: '95г', price: '200р', isNew: true },
    { name: 'Шоколад Ritter Sport', detail: '100г/16г', price: '280/50р', isNew: true }
  ];

  // Блок red bull
    const redbullItems = [
    { name: 'Red Bull Energy Drink', price: '250р', detail: '(Классический)', isNew: true },
    { name: 'Red Bull Sugarfree', price: '250р', detail: '(Без сахара)', isNew: true },
    { name: 'Red Bull Red Edition', price: '250р', detail: '(Арбузный)', isNew: true },
    { name: 'Red Bull Tropical Edition', price: '250р', detail: '(Тропический)', isNew: true },
    { name: 'Red Bull Blue Edition', price: '250р', detail: '(Черничный)', isNew: true },
    { name: 'Red Bull White Edition', price: '250р', detail: '(Кокосовый)', isNew: true }
  ];

    // Блок Пиво
    const beerItems = [
    { name: 'Джоус APA 0,5л Алк. 5.5%', price: '420р', detail: '(С цитрусовыми и тропическими оттенками)', isNew: true },
    { name: 'Джоус Stout 0,5л Алк. 5.2%', price: '420р', detail: '(Темный эль с шоколадом и кофе)', isNew: true },
    { name: 'Джоус Weizen 0.5л Алк. 5.6%', price: '420р', detail: '(Немецкая пшеничная классика)', isNew: true },
    { name: 'Джоус Blanche 0.5л Алк. 4.6%', price: '420р', detail: '(Бланш с кориандром и памеранца)', isNew: true },
    { name: 'Джоус Lager 0.5л Алк. 5.3%', price: '420р', detail: '(Светлый лагер с солодовым вкусом)', isNew: true },
    { name: 'Джоус Helles 0.5л Алк. 4.5%', price: '420р', detail: '(Классический светлый лагер)', isNew: true },
    { name: 'Джоус Citraizen 0.5л Алк. 5.0%', price: '420р', detail: '(Пшеничный эль с цитрусовыми нотами)', isNew: true },
    { name: 'Джоус Nitro 0.5л Алк. 6%', price: '420р', detail: '(Стаут сливочно-кофейный)', isNew: true },
    { name: 'Джоус Sour Breeze Mango 0.5л Алк. 4.5%', price: '420р', detail: '(Кислый соленый эль с манго)', isNew: true },
    { name: 'Джоус Jazz & Juice 0.5л Алк. 6.5%', price: '420р', detail: '(Тропический сорт с фруктовым хмелем)', isNew: true },
    { name: 'Джоус Атомная прачечная 0.5л Алк. 7%', price: '420р', detail: '(Горькое пиво с ароматным хмелем)', isNew: true },
    { name: 'Джоус Екатерина 0.33л Алк. 8.5%', price: '420р', detail: '(Имперский темный стаут с кислинкой)', isNew: true }
  ];
  // Блок кухня
  const kitchenItems = [
    { name: 'Римская Пицца Маргарита', price: '680р', isNew: true },
    { name: 'Римская Пицца 4 сыра', price: '720р', isNew: true },
    { name: 'Римская Пицца Баварская мясная', price: '750р', isNew: true },
    { name: 'Римская Пицца Пепперони', price: '750р', isNew: true },
    { name: 'Картофель фри', detail: '200г', price: '270р', isNew: true },
    { name: 'Луковые кольца', detail: '150г', price: '270р', isNew: true },
    { name: 'Сырные шарики', detail: '150г',price: '320р', isNew: true },
    { name: 'Сырные палочки', detail: '150г', price: '320р', isNew: true },
    { name: 'Картофельные палочки', detail: '150г', price: '270р', isNew: true },
    { name: 'Стрипсы', detail: '180г', price: '320р', isNew: true },
    { name: 'Куриный попкорн', detail: '150г', price: '300р', isNew: true },
    { name: 'Наггетсы', detail: '150г', price: '300р', isNew: true }
  ];

  // TO DO Реализовать блок ДОПЫ

  return (
    <div className="container">
      <Header />

      {/* Прогресс бар прокрутки */}
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '5px',
          backgroundColor: 'red',
          transformOrigin: '0%',
          scaleX: scrollYProgress,
          zIndex: 1000,
          width: '100%',
        }}
      />

      <main>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="hookahs" title="Кальяны" items={hookahItems}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="author-tea" title="Чай Авторский 1л" items={authorTeaItems} isNewLabel={true}/>
        </motion.div>
        {/* <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="signature-tea" title="Чай Фирменный 1 л" items={signatureTeaItems} isNewLabel={true}/>
        </motion.div> */}
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="chines-tea" title="Китайский чай" items={chinesTeaItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="classic-tea" title="Чай классический 0,6 / 1 л" items={classicTeaItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="lemonades" title="Лимонады 0,4 / 1л" items={lemonadeItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="coffee" title="Кофе" items={coffeeItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="drinks" title="Напитки" items={drinksItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="redbull" title="Red Bull 0.25" items={redbullItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="beer" title="Пиво" items={beerItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="snacks" title="Закуски" items={snacksItems} isNewLabel={true}/>
        </motion.div>
        <motion.div initial={{opacity: 0, y: 50}} whileInView={{opacity: 1, y: 0}} transition={{duration: 0.5}}>
          <MenuBlock id="kitchen" title="Кухня" items={kitchenItems} isNewLabel={true}/>
        </motion.div>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
