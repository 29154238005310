import React from 'react';

function MenuBlock({ id, title, items }) {
  const isKitchen = id === 'kitchen';
  const isSnacks = id === 'snacks';
  const isCoffee = id === 'coffee';
  const isDrinks = id === 'drinks';
  return (
    <section className="menu-block" id={id}>
      <h2>{title}</h2>
      {items.map((item, index) => (
        <div key={index}>
          <div className={`menu-item ${item.isAdditional && "extra"}`}>
            <span className="item-name">
              {item.name}
              {(isKitchen || isSnacks || isCoffee || isDrinks) && item.detail && <span className="kitchen-detail"> {item.detail}</span>}
            </span>
            <span className="item-price">{item.price}</span>
          </div>
          {!isKitchen && !isSnacks && !isCoffee && !isDrinks && item.detail && <div className="menu-item-detail"><em>{item.detail}</em></div>}

          <hr className="divider" />
        </div>
      ))}
    </section>
  );
}

export default MenuBlock;
