import React from 'react';

function MenuBlock({ id, title, items }) {
  return (
    <section className="menu-block" id={id}>
      <h2>{title}</h2>
      {items.map((item, index) => (
        <div key={index}>
          <div className={`menu-item ${item.isAdditional && "extra"}`}>
            <span className="item-name">{item.name}</span>
            <span className="item-price">{item.price}</span>
          </div>
          {item.detail && <div className="menu-item-detail"><em>{item.detail}</em></div>}

          <hr className="divider" />
        </div>
      ))}
    </section>
  );
}

export default MenuBlock;
